/**
 * 针对 https://github.com/xaboy/form-create-designer 封装的工具类
 */

// 编码表单 Conf
const encodeConf = (designerRef) => {
  // @ts-ignore
  return JSON.stringify(designerRef.value.getOption());
};

// 编码表单 Fields
const encodeFields = (designerRef) => {
  // @ts-ignore
  const rule = designerRef.value.getRule();
  const fields = [];
  return fields;
};

// 解码表单 Fields
const decodeFields = (fields, value, type) => {
  const rule = [];
  const token = localStorage.getItem("token");
  const userId = Number(localStorage.getItem("userId"));
  const deptId = Number(localStorage.getItem("deptId"));
  fields.forEach((item) => {
    const obj = JSON.parse(item);
    if (value) {
      for (var key in value) {
        if (key === obj.field) {
          obj.value = value[key];
        }
      }
    }
    if (obj.type === "upload") {
      obj.props.onSuccess = function (res, file) {
        file.url = res.data;
      };
      obj.props.onPreview = function (file) {
        window.open(file.url);
      };
      obj.props.onRemove = function (file, fileList) {
        if (value) {
          fileList.push(file);
          const u = navigator.userAgent;
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          if (isiOS) {
            const link = document.createElement("a");
            link.href = file.url;
            link.target = "_blank";
            link.download = "111";
            link.style.display = "none";
            document.body.append(link);
            link.click();
          } else {
            let url = file.url; // 拼接下载地址
            var iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src =
              "javascript: '<script>location.href=\"" + url + "\"</script>'";
            document.getElementsByTagName("body")[0].appendChild(iframe);
          }
        }
        // window.open(file.url);
      };
    }
    if (obj?.effect?.fetch?.action) {
      if (obj.field !== "userId" && obj.field !== "deptId") {
        obj.effect.fetch.headers = {
          Authorization: "Bearer " + token,
        };
      }
    }
    if (obj?.props?.action) {
      obj.props.headers = {
        Authorization: "Bearer " + token,
      };
    }
    if (obj.field === "userId" && !type) {
      obj.value = userId;
    }
    if (obj.field === "deptId" && !type) {
      obj.value = deptId;
    }
    rule.push(obj);
  });
  console.log(rule);
  return rule;
};

// 设置表单的 Conf 和 Fields
const setConfAndFields = (designerRef, conf, fields) => {
  // @ts-ignore
  designerRef.value.setOption(JSON.parse(conf));
  // @ts-ignore
  designerRef.value.setRule(decodeFields(fields));
};

// 设置表单的 Conf 和 Fields
const setConfAndFields2 = (detailPreview, conf, fields, value, type) => {
  // @ts-ignore
  // console.log('--------------', detailPreview, conf, fields, value);
  // console.log('--------------', fields);
  detailPreview.option = JSON.parse(conf);
  detailPreview.option.form.labelPosition = "top";
  // @ts-ignore
  detailPreview.rule = decodeFields(fields, value, type);
  if (value) {
    // @ts-ignore
    detailPreview.value = value;
  }
};

export default {
  encodeConf,
  encodeFields,
  decodeFields,
  setConfAndFields,
  setConfAndFields2,
};
