<template>
  <van-nav-bar left-arrow @click-left="onClickLeft" />
</template>

<script>
export default {
  components: {},
  computed: {
    name() {
      return this.data;
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      window.history.go(-1);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
