<template>
	<div>
		<BaseNav v-if="isHasNav"></BaseNav>
		<!-- todo(待办)   toDone（已办） history（我的流程） monitor（流程监控） copy（消息通知）-->
		<van-cell-group>
			<!-- 
            <van-cell>
              <template #title>
                <div class="title">
                  <div class="line"></div>
                  <div>流程状态</div>
                </div>
              </template>
              <template #label>
                <span :class="variables.status === 1 ? 'blue' : 'red'">
                  {{ variables.status == 1 ? "进行中" : "已完成" }}
                </span>
              </template>
            </van-cell> 
            -->
			<van-cell class="title-wrap">
				<template #title>
					<div style="
                            font-weight: 600;
                            font-size: 16px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                        ">
						{{ flowInfo.name }}
					</div>
					<div>申请时间：{{ formatTime(flowInfo.createTime) }}</div>
				</template>
				<img v-if="flowInfo.result" width="60px" :src="resultMap[flowInfo.result].img" alt="" />
			</van-cell>
		</van-cell-group>
		<br />

		<van-tabs v-model="active" line-width="20" line-height="4" title-active-color="#037BE7" color="#037BE7"
			@change="changeTab">
			<van-tab title="表单详情">
				<div class="whitebox minHeiBox">
					<!-- <div id="rootRender"  style="overflow-x: unset"></div> -->

					

					<template v-if="isNewFc">
						<template v-for="(item, index) in amisList">
							<AmisRender :key="index" v-if="item.formConf" :jsonData="item.formConf"
								:jsonOtherData="item.formVariables || {}" @load="loadAmis"></AmisRender>
						</template>
					</template>

					<form-create ref="fApi" :rule="detailForm.rule" v-model="detailForm.value"
						:option="detailForm.option" v-else />

					<template v-if="(page == 'todo' || page == 'history' || page == 'monitor' || page == 'appMsg') && flowInfo.result!=2 && isCurrentUser">
						<van-form ref="otherForm" validate-first>
							<van-field readonly clickable name="picker" :value="formData.copyUserIdsStr" label="抄送人"
								placeholder="点击选择抄送人" @click="openCopyUser"></van-field>

							<van-field v-model="formData.reason" rows="2" autosize label="审批意见" type="textarea"
								maxlength="500" placeholder="请输入审批意见" show-word-limit
								:rules="[{ required: true, message: '' }]"></van-field>
						</van-form>
						<br />
						<br />
						<br />
						<br />
					</template>
				</div>
			</van-tab>
			<van-tab title="流程图" class="canvas_tab">
				<div class="containers animate" id="containers">
					<div class="canvas" ref="canvas"></div>
				</div>
			</van-tab>
			<van-tab title="处理日志">
				<div class="minHeiBox">
					<van-steps active="99999" direction="vertical">
						<van-step v-for="(item, index) in taskList" :key="index" :style="{
                                color: resultMap[item.result].color,
                            }">
							<div style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                ">
								<div>{{ item.name }} {{ item.assigneeUser?.nickname }}</div>
								<div v-if="item.endTime">{{ formatDate(item.endTime) }}</div>
							</div>
							<div style="
                                    box-shadow: 0 0.02rem 0.12rem rgba(0, 0, 0, 0.1);
                                    border-radius: 0.8rem;
                                    overflow: hidden;
                                    margin-bottom: 2rem;
                                    padding: 2rem 1rem;
                                    margin-top: 1.4rem;
                                ">
								<div>
									{{ resultMap[item.result].text }}
								</div>
								<div>信息: {{ item.endTime ? item.reason : "-" }}</div>
								<div v-if=" item.copyUsernames">抄送人: {{ item.copyUsernames}}</div>
							</div>
						</van-step>
					</van-steps>
				</div>
			</van-tab>
		</van-tabs>
		<template v-if="page == 'history' || page == 'monitor'">
			<div class="bottomBtnsCanel">
				<div class="bottomBtnsCanelItem"  v-if="isShowRetract && flowInfo.result==1 " >
					<van-button  type="info" plain @click="retractHandle" >
					撤回
				</van-button>
				</div>
				<div class="bottomBtnsCanelItem" v-if="showDelBtn==1 && taskList[0].deleteAble">
					<van-button    type="info" @click="deleteHandle">
					取消
				</van-button>
				</div>
			</div>
		</template>
		<template v-if="page == 'toDone' || page == 'monitor'">
			<div class="bottomBtnsCanel" v-if="showWithdrawBtn">
				<div class="bottomBtnsCanelItem"  >
					<van-button  type="info"  @click="withdrawHandle" >
					撤回
				</van-button>
				</div>
			</div>
		</template>


		<template v-if="(page == 'todo' || page == 'history' || page == 'monitor' || page == 'appMsg') && isCurrentUser">
			<div class="bottomBtns">
				<div :class="isShowSaveBtn?'bottomItems':'bottomBtnsItem'" >
					<van-button type="info" :loading="passLoading" style="
				        
				        font-size: 0.8rem;
				        background: #4988FF;
				    " @click="onSubmit" size="normal">通过</van-button>
				</div>
				<!-- &nbsp; -->
				<div :class="isShowSaveBtn?'bottomItems':'bottomBtnsItem'"  v-if="!isFirstTask">
					<van-button  style=" font-size: 0.8rem"
						:loading="transferUser" @click="openTransfer" plain type="info" size="normal">转办</van-button>
					<!-- <img width="60px"  :src="require('@/assets/img/transfer.png')" alt="" @click="openTransfer"/> -->
				</div>
				<!-- &nbsp; -->
				<div :class="isShowSaveBtn?'bottomItems':'bottomBtnsItem'"  v-if="!isFirstTask">
					<van-button type="danger" style=" background: #f56c6c; font-size: 0.8rem"
						:loading="rejectLoading" @click="rejectFirst" size="normal">退回</van-button>
				</div>
				<!-- &nbsp; -->
				<div :class="isShowSaveBtn?'bottomItems':'bottomBtnsItem'" v-if="isShowSaveBtn">
					<van-button type="primary" :loading="saveLoading" style=" font-size: 0.8rem"
						@click="saveSubmit" size="normal">保存</van-button>
				</div>
					<!-- &nbsp; -->

				<div :class="isShowSaveBtn?'bottomItems':'bottomBtnsItem'"  v-if="taskList[0].deleteAble">
					<van-button type="info" plain style="font-size: 0.8rem"
					@click="deleteHandle" size="normal">取消</van-button>
				</div>

				<!-- 
                <van-button
                    round
                    type="warning"
                    style="padding: 0.2rem 1.8rem; background: #e6a23c"
                    @click="backFlow"
                >
                    回退
                </van-button> 
                -->
			</div>
		</template>

		<van-popup v-model="show" z-index="1000" position="bottom" :style="{ height: '45%' }" @close="closeBackModal">
			<br />

			<van-form class="backForm" label-position="top" label-width="120px" :model="backForm" :rules="backFormRules"
				ref="backForm">
				<van-field readonly clickable name="picker" :value="backForm.rollbackIdStr" label="回退节点"
					placeholder="点击选择回退节点" :rules="[{ required: true, message: '选择回退节点' }]"
					@click="showIds = true"></van-field>

				<van-field v-model="backForm.comment" rows="2" autosize label="回退备注" type="textarea" maxlength="500"
					placeholder="请输入回退备注" show-word-limit :rules="[{ required: true, message: '请输入回退备注' }]"></van-field>

				<br />
				<br />

				<div style="margin: 16px">
					<van-button round block type="info" native-type="button" @click="backSubmit">
						提交
					</van-button>
				</div>
			</van-form>
		</van-popup>

		<SelectUser :open="showUsers" :userIds="selectedValues" @save="saveCopyUser" @cancel="cancelCopyUser" />

		<SelectUser type="radio" :open="transferUser" :userId="selectedValue" saveText="确认转办" @save="saveTransferUser"
			@cancel="cancelTransferUser" />

		<!-- 回退节点 -->
		<van-popup v-model="showIds" round position="bottom" style="height: 30%" @close="onConfirmIds">
			<van-radio-group v-model="selectedIds">
				<van-cell-group>
					<van-cell v-for="item in backIds" clickable :key="item.id" :title="item.name"
						@click="selectedIds = item.id">
						<template #right-icon>
							<van-radio :name="item.id" />
						</template>
					</van-cell>
				</van-cell-group>
			</van-radio-group>
		</van-popup>
		<van-dialog v-model="showDel" title="取消原因" show-cancel-button :before-close="onBeforeClose">
				<van-field v-model="deleteReason" required label="原因" clearable placeholder="请输入取消原因" />
		</van-dialog>
	</div>
</template>

<script>
	import {
		approveFlow,
		saveTaskInfo,
		getActiveList,
		getBackList,
		getHistoryFlowList,
		getMultipleNodes,
		getProcessInstance,
		getUserList,
		rejectFlow,
		retractFlow,
		rollbackFlow,
		updateTaskAssignee,
		deleteProcessInstance,
		taskRevokeTask,
		taskGetRevocable
	} from "@/api/flow";
	import {
		formatTime
	} from "@/utils/DateUtil";

	import AmisRender from "@/components/AmisRender";
	import BaseNav from "@/components/BaseNav";
	import SelectUser from "@/components/SelectUser";

	import fc from "@/utils/formCreate";

	// import BpmnJS from "bpmn-js/dist/bpmn-modeler.development";
	import BpmnJS from "@/assets/js/bpmn";
	import Hammer from "hammerjs/hammer";

	import {
		resultMap
	} from "@/constants/map";
	import {
		Toast
	} from "vant";

	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	export default {
		components: {
			AmisRender,
			BaseNav,
			SelectUser
		},
		// props: {
		// 	page: {
		// 		type: String,
		// 		default: "",
		// 	},
		// },
		data() {
			return {
				page:"",
				showWithdrawBtn:false,
				isFirstTask:false,
				//
				showDelBtn: null,
				showDel: false,
				deleteReason: "",
				deleteId: "",
				//
				flowInfo: {},
				saveLoading: false,
				isShowSaveBtn: false,
				passLoading: false,
				rejectLoading: false,
				active: 0,
				variables: {
					status: 0,
				},
				processInstanceId: null,
				processDefinitionId: null,
				src: "",
				renderedStartForm: {},
				variablesArr: [],
				taskList: [],
				imageJson: {},
				detailForm: {
					rule: [],
					option: {},
					value: {},
				},
				fApi: {},
				activityList: [],
				bpmnControlForm: {
					prefix: "flowable",
				},
				processInstance: {},
				tasks: [],
				bpmnXml: "",
				bpmnModeler: null,
				formList: [],

				formData: {
					reason: "",
					copyUserIds: "",
					copyUserIdsStr: "",
					userId: Number(localStorage.getItem("userId")),
				},
				rules: {
					reason: [{
						required: true,
						message: "请填写审批意见",
						trigger: "blur",
					}, ],
				},
				approveId: "",

				userOptions: [],
				userAllOptins: [],

				isNewFc: false,

				jsonData: {},
				jsonOtherData: {},

				show: false,
				backIds: [],
				backForm: {
					rollbackId: "",
					rollbackIdStr: "",
					comment: "",
				},
				backFormRules: {
					rollbackId: [{
						required: true,
						message: "请选择回退节点",
						trigger: "blur",
					}, ],
					comment: [{
						required: true,
						message: "请填写回退备注",
						trigger: "blur",
					}, ],
				},

				showUsers: false,
				showIds: false,

				amisScoped: [],

				selectedValues: [],
				selectedIds: "",
				formKey: "",

				copyName: "",

				amisList: [],

				transferUser: false,
				selectedValue: "",
			};
		},
		computed: {
			isCurrentUser() {
				const hasres = this.taskList.filter((val) => val.result === 1);
				console.log("hasres - >:", hasres);
				const isHas = hasres.some(
					(item) => item?.assigneeUser?.id == localStorage.getItem("userId")
				);
				console.log("isHas - >:", isHas);

				return isHas;
			},
			resultMap() {
				return resultMap;
			},

			isHasNav() {
				return this.$route.query.hasNav;
			},

			isShowRetract() {
				// 判断第一个是否存在
				const startUserIsAssignee = this.taskList[0]?.startUserIsAssignee;
				if (startUserIsAssignee) {
					return true;
				}
				return false;
			},
		},
		watch: {},
		async mounted() {
			this.processInstanceId = this.$route.query.id;
			this.processDefinitionId = this.$route.query.processDefinitionId;
			this.approveId = this.$route.query.taskId;
			this.formData.id = this.$route.query.taskId;
			this.page = this.$route.query.page;
			this.showWithdrawHandle();
			await this.getActivityList();
			await this.getTaskList();
			// await this.getBackId();

			window.BpmnJS = BpmnJS;
			window.Hammer = Hammer;

			// 默认滚动到顶部
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
		methods: {
			formatTime,
			currentimg() {
				console.log("this.flowInfo.result - >:", this.flowInfo.result);
				return require(this.resultMap[this.flowInfo.result].img);
			},
			resize(newRect) {},
			loadAmis(res) {
				this.amisScoped = [...this.amisScoped, res];
			},
			getDetails() {
				let params = {
					id: this.processInstanceId,
				};
				getProcessInstance(params)
					.then((res) => {
						console.log("res ->:", res);
						if (res.status == 200) {
							this.flowInfo = res.data || {};
							this.processInstance = res.data || {};
							this.variables.status = res.data.status;
							this.showDelBtn = res.data.result;
							this.deleteId = res.data.id;
							const {
								formFields = [], formConf = "{}"
							} =
							res.data.processDefinition || {};
							
							if (formFields[0] && formFields[0].includes("thisIsNew")) {
								this.isNewFc = true;
								// 合并数据,把请求的合并 taskList 到 amisList 里面,判断不同条件
								const allDataList = JSON.parse(
									JSON.stringify([...this.taskList, res.data])
								)
								const allData = allDataList.map((item, idx) => {
									if (item?.processDefinition?.formConf) {
										item.formConf = item.processDefinition.formConf;
									}
									

									// 不展示这个表单节点
									if (item.readAble == 0) {
										if(allDataList[0].definitionKey!=allDataList[allDataList.length-2].definitionKey){
											item.formConf = null;
										}
										
									}

									return item;
								});
								
								const [one, ...restData] = allData;
								
								let result = [];
								if(one.formKey){
									allData.forEach((i,index)=>{
										if(i.formKey == allData[allData.length-1].formKey && (index!=allData.length-1)){
											i.formConf = null;
										}
									})
								}
								if (one.formId) {
									if(one.formKey && (one.formId == one.formKey == restData[restData.length-1].formKey)){
										result = restData.map((item, index)=>{
											if (item.formConf) {
												item.formConf = JSON.parse(item.formConf);
												if(index==restData.length-1){
													item.formConf.body[0].static = false;
												    item.formConf.body[0].disabled = false;
												}else{
													item.formConf = null;
												}
											}
											return item;
										})
									}else{
									// 存在可以编辑的表单 和剩下的数组对比
									result = restData.map((item, idx) => {
										if (item.formConf) {
											item.formConf = JSON.parse(item.formConf);
											//正常节点
											if (one.formId == item.formKey) {
												item.formConf.body[0].name = "submitForm";
												//表格编辑
												item.formConf.body[0].body.forEach((field) => {
													if(one.editFields && one.editFields.length && field.type=='collapse'){
														let showColl = one.editFields.some(edit=>edit.includes('collapse.'));
														if(showColl && field.body && field?.body[0]){
															field?.body[0]?.items[0]?.body[0]?.body.forEach((collItem)=>{
																if(one.editFields.includes(`collapse.${collItem.name}`)){
																	collItem.static = false;
																	collItem.disabled = false;
																}
															})
															
														}else{
															if (field.type == "collapse") {
																field.hidden = true;
															}
														}
														
													}else{
														if (field.type == "collapse") {
															field.hidden = true;
														}
													}
													if ((one.editFields && one.editFields.includes(field.name)) || (one.downloadFields && one.downloadFields.includes(field.name))) {
														field.static = false;
														field.disabled = false;
													} else {
														if(one.formKey && (one.formKey == restData[restData.length-1].formKey)){
															field.static = false;
															// field.disabled = false;
														}else{
															field.static = true;
															field.disabled = true;
														}
													}
													if(field?.disabled==true){
														field.required = false;
													}
												});
											} else {
												item.formConf.body[0].static = true;
												item.formConf.body[0].disabled = true;
												item.formConf.body[0].body.forEach((item) => {
													if (item.type == "collapse") {
														item.hidden = true;
													}
													if(item?.disabled==true){
														item.required = false;
													}
												});
											}
										}
										return item;
									});
									}
									this.isShowSaveBtn = false;
								} else {
									// 多节点或退回节点
									result = allData.map((item, idx) => {
										if (item.formConf) {
											item.formConf = JSON.parse(item.formConf);
											if (item.formKey && idx === 0) {
												item.formConf.body[0].name = "submitForm";
												item.formConf.body[0].static = false;
												item.formConf.body[0].disabled = false;
												item.formConf.body[0].body.forEach((item) => {
												if (item.type == "collapse") {
													item.hidden = false;
												}
												if(item?.disabled==true){
													item.required = false;
												}
											});
												this.isShowSaveBtn = true;
											}else if(allData[0].formKey == allData[allData.length-1].formKey){
												item.formConf.body[0].name = "submitForm";
												item.formConf.body[0].static = false;
												item.formConf.body[0].disabled = false;
												item.formConf.body[0].body.forEach((item) => {
												if (item.type == "collapse") {
													item.hidden = false;
												}
												if(item?.disabled==true){
													item.required = false;
												}
											});
											} else {
												item.formConf.body[0].static = true;
												item.formConf.body[0].disabled = true;
												item.formConf.body[0].body.forEach((item) => {
													if (item.type == "collapse") {
														item.hidden = true;
													}
												});
											}
										}

										return item;
									});
								}
								this.amisList = result;
								console.log('222222-------------------->',this.amisList);
							} else {
								this.isNewFc = false;
								if (!formFields.length) {
									return;
								}

								fc.setConfAndFields2(
									this.detailForm,
									formConf,
									formFields,
									res.data.formVariables
								);

								this.$nextTick(() => {
									this.$refs.fApi.option.resetBtn.show = false;
									this.$refs.fApi.option.submitBtn.show = false;
									this.$refs.fApi.option.form.disabled = true;
								});
							}
						}
					})
					.catch((err) => {
						console.log("网络错误", err);
					});
			},
			getTaskList() {
				let params = {
					processInstanceId: this.processInstanceId,
				};
				getMultipleNodes(params)
					.then((res) => {
						if (res.status == 200) {
							this.taskList = res.data;
							this.isFirstTask = res.data[0].isFirstTask;
							if (this.taskList && this.taskList.length) {
								if (this.taskList[0].copyUserIds) {
									this.formData.copyUserIds = this.taskList[0].copyUserIds.split(',').map(Number);
									this.formData.copyUserIdsStr = this.taskList[0].copyUsernames;
									this.selectedValues = this.formData.copyUserIds;
								}
							}
							this.formKey = res.data[0].formKey;

							// 按顺序调用
							this.getDetails();
						}
					})
					.catch((err) => {
						console.log("网络错误");
					});
			},
			getActivityList() {
				let params = {
					processInstanceId: this.processInstanceId,
				};
				getActiveList(params).then((res) => {
					this.activityList = res.data;
				});
			},

			getImage() {
				let params = {
					id: this.processInstanceId,
				};
				getProcessInstance(params)
					.then((res) => {
						if (res.status == 200) {
							const canvas = this.$refs.canvas;
							this.bpmnModeler && this.bpmnModeler.destroy();
							this.bpmnModeler = new BpmnJS({
								container: canvas,
								height: 300,
							});
							this.bpmnXml = res.data.processDefinition.bpmnXml;
							const self = this;
							this.bpmnModeler.importXML(this.bpmnXml, function(err) {
								if (err) {
									console.log("err -> :", err);
								} else {
									self.highlightDiagram();
									self.methodssss();
								}
							});
						}
					})
					.catch((err) => {
						console.log("err -> :", err);
						console.log("网络错误");
					});
			},
			highlightDiagram() {
				const activityList = this.activityList;
				if (activityList.length === 0) {
					return;
				}
				let canvas = this.bpmnModeler.get("canvas");
				canvas.zoom("fit-viewport", "auto");
				let todoActivity = activityList.find((m) => !m.endTime); // 找到待办的任务
				let endActivity = activityList[activityList.length - 1]; // 获得最后一个任务
				try {

					this.bpmnModeler.getDefinitions().rootElements[0].flowElements?.forEach((n) => {
						let activity = activityList.filter((m) => m.key === n.id); // 找到对应的活动
						if (!activity.length) {
							return;
						}
						if (n.$type === "bpmn:UserTask") {
							// 用户任务
							// 处理用户任务的高亮
							const task = this.taskList.find((m) => m.id === activity[0].taskId); // 找到活动对应的 taskId
							if (!task) {
								return;
							}
							// 高亮任务
							if(!n.id || !this.getResultCss(task.result)){
								return;
							}
							canvas.addMarker(n.id, this.getResultCss(task.result));
							// 如果非通过，就不走后面的线条了
							// if (task.result !== 2) {
							// 	throw Error();
							// }
							// 处理 outgoing 出线
							const outgoing = this.getActivityOutgoing(activity);
							outgoing?.forEach((nn) => {
								// debugger
								let targetActivity = activityList.find(
									(m) => m.key === nn.targetRef.id
								);
								// 如果目标活动存在，则根据该活动是否结束，进行【bpmn:SequenceFlow】连线的高亮设置
								if (targetActivity) {
									canvas.addMarker(
										nn.id,
										targetActivity.endTime ? "highlight" : "highlight-todo"
									);
								} else if (nn.targetRef.$type === "bpmn:ExclusiveGateway") {
									// TODO 芋艿：这个流程，暂时没走到过
									canvas.addMarker(
										nn.id,
										activity.endTime ? "highlight" : "highlight-todo"
									);
									canvas.addMarker(
										nn.targetRef.id,
										activity.endTime ? "highlight" : "highlight-todo"
									);
								} else if (nn.targetRef.$type === "bpmn:EndEvent") {
									// TODO 芋艿：这个流程，暂时没走到过
									if (!todoActivity && endActivity.key === n.id) {
										canvas.addMarker(nn.id, "highlight");
										canvas.addMarker(nn.targetRef.id, "highlight");
									}
									if (!activity.endTime) {
										canvas.addMarker(nn.id, "highlight-todo");
										canvas.addMarker(nn.targetRef.id, "highlight-todo");
									}
								}
							});
						
						} else if (n.$type === "bpmn:ExclusiveGateway") {
							// 排它网关
							// 设置【bpmn:ExclusiveGateway】排它网关的高亮
							canvas.addMarker(n.id, getActivityHighlightCss(activity));
							// 查找需要高亮的连线
							let matchNN = undefined;
							let matchActivity = undefined;
							n.outgoing?.forEach((nn) => {
								let targetActivity = activityList.find(
									(m) => m.key === nn.targetRef.id
								);
								if (!targetActivity) {
									return;
								}
								// 特殊判断 endEvent 类型的原因，ExclusiveGateway 可能后续连有 2 个路径：
								//  1. 一个是 UserTask => EndEvent
								//  2. 一个是 EndEvent
								// 在选择路径 1 时，其实 EndEvent 可能也存在，导致 1 和 2 都高亮，显然是不正确的。
								// 所以，在 matchActivity 为 EndEvent 时，需要进行覆盖~~
								if (!matchActivity || matchActivity.type === "endEvent") {
									matchNN = nn;
									matchActivity = targetActivity;
								}
							});
							if (matchNN && matchActivity) {
								canvas.addMarker(
									matchNN.id,
									getActivityHighlightCss(matchActivity)
								);
							}
						} else if (n.$type === "bpmn:ParallelGateway") {
							// 并行网关
							// 设置【bpmn:ParallelGateway】并行网关的高亮
							canvas.addMarker(n.id, getActivityHighlightCss(activity));
							n.outgoing?.forEach((nn) => {
								// 获得连线是否有指向目标。如果有，则进行高亮
								const targetActivity = activityList.find(
									(m) => m.key === nn.targetRef.id
								);
								if (targetActivity) {
									canvas.addMarker(
										nn.id,
										getActivityHighlightCss(targetActivity)
									); // 高亮【bpmn:SequenceFlow】连线
									// 高亮【...】目标。其中 ... 可以是 bpm:UserTask、也可以是其它的。当然，如果是 bpm:UserTask 的话，其实不做高亮也没问题，因为上面有逻辑做了这块。
									canvas.addMarker(
										nn.targetRef.id,
										getActivityHighlightCss(targetActivity)
									);
								}
							});
						} else if (n.$type === "bpmn:StartEvent") {
							// 开始节点
							n.outgoing?.forEach((nn) => {
								// outgoing 例如说【bpmn:SequenceFlow】连线
								// 获得连线是否有指向目标。如果有，则进行高亮
								let targetActivity = activityList.find(
									(m) => m.key === nn.targetRef.id
								);
								if (targetActivity) {
									canvas.addMarker(nn.id, "highlight"); // 高亮【bpmn:SequenceFlow】连线
									canvas.addMarker(n.id, "highlight"); // 高亮【bpmn:StartEvent】开始节点（自己）
								}
							});
						} else if (n.$type === "bpmn:EndEvent") {
							// 结束节点
							if (!this.processInstance || this.processInstance.result === 1) {
								return;
							}
							canvas.addMarker(n.id, this.getResultCss(this.processInstance.result));
						} else if (n.$type === "bpmn:ServiceTask") {
							//服务任务
							if (activity.startTime > 0 && activity.endTime === 0) {
								//进入执行，标识进行色
								canvas.addMarker(n.id, this.getResultCss(1));
							}
							if (activity.endTime > 0) {
								// 执行完成，节点标识完成色, 所有outgoing标识完成色。
								canvas.addMarker(n.id, this.getResultCss(2));
								const outgoing = this.getActivityOutgoing(activity);
								outgoing?.forEach((out) => {
									canvas.addMarker(out.id, this.getResultCss(2));
								});
							}
						}
					});
				} catch (error) {
					console.log('error-------------》',error)
				}
			},
			getResultCss(result) {
				if (result === 1) {
					// 审批中
					return "highlight-todo";
				} else if (result === 2) {
					// 已通过
					return "highlight";
				} else if (result === 3 || result === 5) {
					// 不通过
					return "highlight-reject";
				} else if (result === 4) {
					// 已取消
					return "highlight-cancel";
				}
				return "";
			},
			getActivityOutgoing(activity) {
				console.log("activity - >:", activity);
				// 如果有 outgoing，则直接使用它
				if (activity.outgoing && activity.outgoing.length > 0) {
					return activity.outgoing;
				}
				// 如果没有，则遍历获得起点为它的【bpmn:SequenceFlow】节点们。原因是：bpmn-js 的 UserTask 拿不到 outgoing
				const flowElements = this.bpmnModeler.getDefinitions().rootElements[0].flowElements;
				const outgoing = [];
				flowElements.forEach((item) => {
					if (item.$type !== "bpmn:SequenceFlow") {
						return;
					}
					if (item.sourceRef.id === activity.key) {
						outgoing.push(item);
					}
				});
				return outgoing;
			},

			changeTab(e) {
				if (e == 0) {
					this.getDetails();
				} else if (e == 1) {
					// $(".imageJson").eq(0).html("");
					this.getImage();
				} else if (e == 2) {
					this.getTaskList();
				}
			},
			formatDate(time) {
				var date = new Date(time);
				var Y = date.getFullYear() + "-";
				var M =
					(date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
					"-";
				var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
				var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
				var m =
					(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
				var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				var strDate = Y + M + D + h + m + s;
				return strDate;
			},

			methodssss() {
				var reqAnimationFrame = (function() {
					return (
						window[Hammer.prefixed(window, "requestAnimationFrame")] ||
						function(callback) {
							window.setTimeout(callback, 1000 / 60);
						}
					);
				})();

				var el = document.querySelector("#containers");

				var START_X = Math.round((window.innerWidth - el.offsetWidth) / 2);
				var START_Y = 0;

				var ticking = false;
				var transform; //图像效果
				var timer;
				var initAngle = 0; //旋转角度
				var initScale = 1; //放大倍数

				var mc = new Hammer.Manager(el); //用管理器  可以同时触发旋转 拖拽  移动
				//var mc = new Hammer(el);	      //旋转和移动互斥
				/**
				ev.srcEvent.type  touchstart  touchend touchmove
				ev.deltaX  手势移动位移变量
				*/
				mc.add(new Hammer.Pan({
					threshold: 0,
					pointers: 0
				}));
				mc.add(new Hammer.Rotate({
					threshold: 0
				})).recognizeWith(mc.get("pan"));
				mc.add(new Hammer.Pinch({
					threshold: 0
				})).recognizeWith([
					mc.get("pan"),
					mc.get("rotate"),
				]);
				//结束时做一些处理
				mc.on("hammer.input", function(ev) {
					if (ev.isFinal) {
						// console.log(
						//   START_X + "  " + transform.translate.x + "   " + ev.deltaX
						// );
						START_X = transform.translate.x;
						START_Y = transform.translate.y;
					}
				});
				mc.on("panstart panmove", onPan);
				// mc.on("rotatestart rotatemove rotateend", onRotate);
				mc.on("pinchstart pinchmove", onPinch);
				/**
				第二次进入拖拽时  delta位移重置
				移动时 初始位置startxy不动。delta增加
				*/
				function onPan(ev) {
					if (!ev.isFinal) {
						el.className = "";
						// console.log(
						//   START_X + "  " + START_Y + " |  " + ev.deltaX + "  " + ev.deltaY
						// );
						transform.translate = {
							x: START_X + ev.deltaX,
							y: START_Y + ev.deltaY,
						};
						requestElementUpdate();
					}
				}

				function onPinch(ev) {
					if (ev.type == "pinchstart") {
						initScale = transform.scale || 1;
					}
					el.className = "";
					transform.scale = initScale * ev.scale;
					requestElementUpdate();
				}

				//旋转相关
				var preAngle = 0;
				var tempAngleFlag = 0;
				var deltaAngle = 0;
				var startRotateAngle = 0;

				// function onRotate(ev) {

				// 	//点下第二个触控点时触发
				// 	if(ev.type == 'rotatestart') {
				// 			startRotateAngle =  ev.rotation ;
				// 			tempAngleFlag = 0 ;
				// 	}
				// 	if(ev.type == 'rotatemove'){
				// 		if(tempAngleFlag == 0){
				// 			preAngle = startRotateAngle;
				// 			tempAngleFlag ++;
				// 		}else{
				// 			deltaAngle = ev.rotation - preAngle;
				// 			el.className = '';
				// 			transform.rz = 1;  //非0  垂直xy轴
				// 			transform.angle =initAngle + deltaAngle;
				// 			requestElementUpdate();
				// 		}
				// 	}

				// 	//旋转结束  记录当前图片角度
				// 	if(ev.type =='rotateend'){
				// 		initAngle = transform.angle;
				// 	}
				// }

				function updateElementTransform() {
					var value = [
						"translate3d(" +
						transform.translate.x +
						"px, " +
						transform.translate.y +
						"px, 0)",
						"scale(" + transform.scale + ", " + transform.scale + ")",
						"rotate3d(" +
						transform.rx +
						"," +
						transform.ry +
						"," +
						transform.rz +
						"," +
						transform.angle +
						"deg)",
					];

					value = value.join(" ");
					el.style.webkitTransform = value; /*为Chrome/Safari*/
					el.style.mozTransform = value; /*为Firefox*/
					el.style.transform = value; /*IE Opera?*/
					ticking = false;
				}

				function requestElementUpdate() {
					if (!ticking) {
						reqAnimationFrame(updateElementTransform);
						ticking = true;
					}
				}

				/**
				初始化设置
				*/
				function resetElement() {
					el.className = "animate";
					transform = {
						translate: {
							x: START_X,
							y: 0
						},
						scale: 1,
						angle: 0,
						rx: 0,
						ry: 0,
						rz: 0,
					};
					requestElementUpdate();
				}
				resetElement();
			},

			/* ----------------------------------- 抄送人 ---------------------------------- */
			openCopyUser() {
				this.showUsers = true;
			},
			saveCopyUser(data) {
				this.selectedValues = data.map((it, ind) => it.value);
				this.formData.copyUserIdsStr = data.map((it, ind) => it.nickname).join(",");
				this.formData.copyUserIds = data.map((it, ind) => it.value);

				this.cancelCopyUser();
			},
			cancelCopyUser(data) {
				this.showUsers = false;
			},

			getBackId() {
				try {
					getBackList({
						taskId: this.approveId,
					}).then((res) => {
						if (res.status == 200) {
							this.backIds = res.data;
						}
					});
				} catch (e) {
					this.backIds = [];
				}
			},

			//撤回显示
			async showWithdrawHandle(){
				try {
						let data = await taskGetRevocable({processInstanceId:this.processInstanceId,taskId:this.approveId});
						if(data.status=='200'){
							this.showWithdrawBtn = data.data;
						}
				} catch (error) {
					
				}
				
			},

			//撤回
			withdrawHandle(){
				let params = {
					processInstanceId:this.processInstanceId,
					taskId:this.approveId
				};

				taskRevokeTask(params)
					.then((res) => {
						if (res.data) {
							this.$toast("操作成功");
							setTimeout(() => {
								history.go(-1);
							}, 1000);
						} else {
							this.$toast(res.message);
						}
					})
					.catch((err) => {
						console.log("网络错误");
					});
			},

			//取消
			deleteHandle() {
				this.showDel = true;
			},
			//取消操作
			onBeforeClose(action, done) {
				// 点击了确定按钮
				if (action === "confirm") {
					if (this.deleteReason) {
						deleteProcessInstance({
							id: this.deleteId,
							reason: this.deleteReason
						}).then((res) => {
							if (res.status == 200) {
								Toast({
									message: '取消成功',
								});
								done(true)
								setTimeout(() => {
									history.go(-1);
								}, 1000);
							} else {
								this.$toast(res.message);
								return done(false);
							}
						});

					} else {
						Toast({
							message: '原因不能为空',
						});
						return done(false);
					}

					//直接return它即可阻止
				}
				// 点击了取消按钮
				else {
					done(true); //关闭弹窗, true可以省略
				}
			},

			//强制收回
			retractHandle() {
				let params = {
					processInstanceId: this.processInstanceId,
				};

				// this.$router.replace({
				//   path:
				//     "/sendInfo?id=" +
				//     this.processDefinitionId +
				//     `${this.isHasNav ? "&hasNav=1" : ""}`,
				// });

				retractFlow(params)
					.then((res) => {
						if (res.data) {
							this.$toast("操作成功");
							setTimeout(() => {
								history.go(-1);
							}, 1000);
						} else {
							this.$toast(res.message);
						}
					})
					.catch((err) => {
						console.log("网络错误");
					});
			},
			//  保存
			async saveSubmit() {
				if (this.amisScoped[0]) {
					const form = this.amisScoped[0].getComponentByName("page.submitForm");
					console.log("form - >:", form);

					if (form) {
						const vals = form.getValues();
						this.formData.formVariables = vals;
						this.formData.formKey = this.formKey;

						console.log("this.formData -> :", this.formData);
					}

					this.saveLoading = true;
					await sleep(600);

					await saveTaskInfo(this.formData)
						.then((res) => {
							if (res.data) {
								this.successHandler();
							} else {
								this.$toast(res.message);
							}
						})
						.finally(() => {
							this.saveLoading = false;
						});
				}
			},
			//  通过审批
			onSubmit() {
				if (!this.formData.reason) return this.$toast.fail("请填写审批意见");

				this.$refs.otherForm
					.validate()
					.then(() => {
						if (this.amisScoped[0]) {
							const form = this.amisScoped[0].getComponentByName("page.submitForm");
							if (this.formData.copyUserIds && this.formData.copyUserIds.length) {
								this.formData.copyUserIds = this.formData.copyUserIds.join(',')
							}else{
								this.formData.copyUserIds = "";
							}
							console.log("form1 - >:", form);


							if (form) {
								form.validate().then((valid) => {
									console.log("表单校验 valid - >:", valid);
									if (!valid) {
										return;
									}

									const vals = form.getValues();
									this.formData.formVariables = vals;
									this.formData.formKey = this.formKey;


									console.log("this.formData1 -> :", this.formData);

									this.passReq(this.formData);
								});
							} else {
								this.passReq(this.formData);
							}
						}
					})
					.catch((e) => {
						console.log("e -> :", e);
					});
			},
			async passReq(formData) {
				this.passLoading = true;
				await sleep(600);
				await approveFlow(formData)
					.then((res) => {
						if (res.data) {
							this.successHandler();
						} else {
							this.$toast(res.message);
						}
					})
					.finally(() => {
						this.passLoading = false;
					});
			},

			/* ----------------------------------- 转发人 ---------------------------------- */
			openTransfer() {
				this.transferUser = true;
			},
			async saveTransferUser(data) {
				if (!data) {
					this.$toast("请选择");
					return;
				}
				await updateTaskAssignee({
					assigneeUserId: data,
					id: this.formData.id
				});
				this.successHandler();
				this.cancelTransferUser();
			},
			cancelTransferUser() {
				this.transferUser = false;
			},

			// 驳回
			rejectFirst() {
				if (!this.formData.reason) return this.$toast.fail("请填写审批意见");
				this.$refs.otherForm
					.validate()
					.then(async () => {
						console.log("this.formData -> :", this.formData);
						if (this.formData.copyUserIds && this.formData.copyUserIds.length) {
							this.formData.copyUserIds = this.formData.copyUserIds.join(',')
						}else{
							this.formData.copyUserIds = "";
						}
						this.rejectLoading = true;
						await sleep(600);
						await rejectFlow(this.formData)
							.then((res) => {
								if (res.data) {
									this.successHandler();
								} else {
									this.$toast(res.message);
								}
							})
							.finally(() => {
								this.rejectLoading = false;
							});
					})
					.catch((e) => {
						console.log("e -> :", e);
					});
			},
			// 回退
			backFlow() {
				this.show = true;
			},
			closeBackModal() {
				this.show = false;
				this.backForm.rollbackId = "";
				this.backForm.comment = "";
				this.selectedIds = "";
			},
			// 回退
			backSubmit() {
				this.$refs.backForm
					.validate()
					.then(() => {
						this.backForm.taskId = this.approveId;
						this.backForm.rollbackId = this.selectedIds;
						console.log("this.backForm -> :", this.backForm);

						rollbackFlow(this.backForm).then((res) => {
							if (res.data) {
								this.successHandler();
							}
						});
					})
					.catch((e) => {
						console.log("e -> :", e);
					});
			},

			// 操作成功
			successHandler() {
				this.$toast("操作成功");
				setTimeout(() => {
					// app消息过来的,通过后去我的已办
					if (this.$route.query.page == "appMsg") {
						this.$router.push("/doDone");
					} else {
						history.go(-1);
					}
				}, 1000);
			},

			onConfirmIds() {
				this.backForm.rollbackIdStr =
					this.backIds.find((val) => val.id == this.selectedIds)?.name || "";
			},
		},
	};
</script>

<style>
	.canvas {
		width: 100%;
		height: 100%;
	}

	.canvas_tab {
		overflow: hidden;
	}

	.panel {
		position: absolute;
		right: 0;
		top: 0;
		width: 600px;
	}

	.animate {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;
	}
</style>
<style lang="less" scoped>
	.title-wrap {
		/deep/ .van-cell__title {
			flex: 2;
		}

		/deep/ .van-button {
			height: 40px;
		}
	}

	/deep/.van-dialog__header {
		font-size: 16px;
		color: grey;
	}

	.title {
		display: flex;
		align-items: center;

		.line {
			width: 0.2rem;
			height: 0.8rem;
			background: #037ae7;
			margin-right: 10px;
		}
	}

	.blue {
		color: #037ae7;
	}

	.red {
		color: #ff4d4f;
	}

	.minHeiBox {
		min-height: calc(100vh - 5rem);
		padding-bottom: 1.4rem;
		background-color: #fff;
		padding: 0.4rem 0;
		margin-top: 0.2rem;
	}

	/deep/ .cxd-Panel-btnToolbar button.cxd-Button {
		display: none !important;
	}

	/deep/ .van-tab__pane {
		min-height: calc(100vh - 3rem) !important;
		background-color: rgba(248, 249, 250, 1) !important;
	}

	.bottomBtns {
		width: calc(100vw - 0rem);
		display: flex;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 999;
		padding: 0.4rem;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		/deep/.van-button{
				width: 100%;
			}
		.bottomItems{
			width: 23%;
			margin: 0 0.3rem;
			
		}
		.bottomBtnsItem{
			width: 30%;
			margin: 0 0.3rem;
		}

	}
	.bottomBtnsCanel {
		width: calc(100vw - 0.8rem);
		display: flex;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 999;
		padding: 0.4rem;
		align-items: center;
		justify-content: space-evenly;
		background-color: #fff;
		.bottomBtnsCanelItem{
			width:40%;
			/deep/.van-button{
				width: 100%;
			}
		}
	}
	.openTransfer{
		width:4rem;
		height:4rem;
		position:fixed;
		right:0;
		bottom:10rem
	}
</style>