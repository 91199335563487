<template>
    <!-- 抄送人 -->
    <van-popup
        v-model="open"
        round
        position="bottom"
        style="height: 55%"
        :close-on-click-overlay="false"
    >
        <div style="display: flex; justify-content: space-between; height: 44px; line-height: 44px">
            <van-button
                style="border: none; color: #969799"
                @click="cancelCopyUser"
                size="normal"
            >
                取消
            </van-button>

            <!-- 
            <span style="font-size: 16px; font-weight: bold">
                抄送人
            </span> 
            -->
            <van-button
                style="border: none; color: #6398fb"
                @click="onConfirm"
                size="normal"
            >
                {{ saveText }}
            </van-button>
        </div>

        <van-search
            v-model="copyName"
            @search="getUsers"
            placeholder="请输入搜索关键词"
        />

        <van-checkbox-group
            v-model="selectedValues"
            v-if="type == 'multiple'"
        >
            <van-cell-group>
                <van-cell
                    v-for="(item, index) in userOptions"
                    clickable
                    :key="item.value"
                    :title="item.label"
                    @click="toggle(index)"
                >
                    <template #title>
                        <!-- <div>{{ item.deptName }}</div> -->
                        <div>{{ item.label }}</div>
                    </template>
                    <template #right-icon>
                        <van-checkbox
                            :name="item.value"
                            ref="checkboxes"
                        />
                    </template>
                </van-cell>
            </van-cell-group>
        </van-checkbox-group>

        <van-radio-group
            v-model="selectedValue"
            v-if="type == 'radio'"
        >
            <van-cell-group>
                <van-cell
                    v-for="item in userOptions"
                    clickable
                    :key="item.value"
                    :title="item.label"
                    @click="selectedValue = item.value"
                >
                    <template #right-icon>
                        <van-radio :name="item.value" />
                    </template>
                </van-cell>
            </van-cell-group>
        </van-radio-group>
    </van-popup>
</template>

<script>
    import { getUserList } from "@/api/flow";

    export default {
        props: {
            saveText: {
                type: String,
                default: "确认",
            },
            type: {
                type: String,
                default: "multiple",
            },
            userId: {
                type: String,
                default: () => "",
            },
            userIds: {
                type: Array,
                default: () => [],
            },
            open: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            open(newValue, oldValue) {
                // this.visiable = newValue;
                if (newValue) {
                    this.getUsers();
                    if (this.type == "multiple") {
                        this.selectedValues = this.userIds;
                    }
                }
            },
        },
        data() {
            return {
                visiable: this.open,

                copyName: "",
                selectedValues: [],
                selectedValue: "",

                userOptions: [],
                userAllOptins: [],
            };
        },

        methods: {
            cancelCopyUser() {
                this.copyName = "";

                this.visiable = false;
                this.$emit("cancel");

                if (this.type == "radio") {
                    this.selectedValue = "";
                }
            },
            getUsers(nickname) {
                try {
                    getUserList({ nickname }).then((res) => {
                        if (res.status == 200) {
                            // 全量的用户
                            if (!nickname) {
                                this.userAllOptins = res.data;
                            }
                            this.userOptions = res.data;
                        }
                    });
                } catch (e) {
                    this.userOptions = [];
                    this.userAllOptins = [];
                }
            },

            onConfirm() {
                if (this.type == "multiple") {
                    const resDataArr = this.userAllOptins.filter((val) => {
                        return (this.selectedValues || []).includes(val.value);
                    });

                    this.$emit("save", resDataArr);
                } else {
                    this.$emit("save", this.selectedValue);
                }

                this.copyName = "";

                // this.visiable = false;
                // this.selectedValues = [];
            },

            toggle(index) {
                this.$refs.checkboxes[index].toggle();
            },
        },
    };
</script>

<style lang="less" scoped></style>
