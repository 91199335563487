<template>
    <div style="overflow-x: unset"></div>
</template>

<!-- eslint-disable no-undef -->
<!-- eslint-disable no-console -->
<script>
    // 取消掉全部走 cdn

    // import "amis/sdk/sdk.css";
    // import "amis/sdk/iconfont.css";
    // import "amis/sdk/helper.css";

    // import "amis/sdk/sdk.js";
    // import "amis/sdk/rest.js";
    // import "amis/sdk/tinymce.js";

    import { Toast } from "vant";
    import { baseURL } from "@/utils/HttpUtil";

    export default {
        name: "amisRender",
        props: {
            jsonData: {},
            jsonOtherData: {},
            type: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                amisScoped: null,

                isGetData: false,
            };
        },
        mounted() {
            this.init();
        },
        components: {},
        watch: {
            jsonOtherData: {
                handler() {
                    this.updateProps();
                },
                deep: true,
                immediate: true,
            },
            jsonData: {
                handler() {
                    this.updateDatas();
                },
                deep: true,
                immediate: true,
            },
        },
        created() {},
        methods: {
            updateProps() {
                this.amisScoped?.updateProps({
                    context: { ...this.jsonOtherData },
                });
            },
            updateDatas() {
                this.amisScoped?.updateSchema({ ...this.jsonData });
            },
            init() {
                const scoped = amisRequire("amis/embed");

                let ins = scoped.embed(
                    this.$el,
                    this.jsonData,

                    {
                        context: {
                            baseUrl: baseURL,
                            ...this.jsonOtherData,
                        },
                    },

                    {
                        notify: (type, msg) => {
                            console.log("type -> :", type,msg);
                            const fn = type === "error" ? "fail" : "success";
                            if (fn == "success") {
                                Toast[fn](msg);
                            }
                            if(fn == "fail"){
                                Toast[fn](msg);
                            }

                            if (fn == "success" && this.type == "send") {
                                setTimeout(() => {
                                    history.go(-1);
                                }, 3000);
                            }
                        },

                        requestAdaptor: function (api) {
                            return {
                                ...api,
                                headers: {
                                    ...api.headers,
                                    Authorization: localStorage.getItem("accessToken"),
                                },
                            };
                        },

                        hideValidateFailedDetail: true,
                    },

                    () => {
                        this.$emit("load", ins);
                    }
                );

                this.amisScoped = ins;
            },
        },
        computed: {},
        destroyed() {
            this.amisScoped?.unmount();
        },
    };
</script>

<style lang="less" scoped></style>
